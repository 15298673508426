import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import axios from 'axios'

// Library Components
import VueSweetalert2 from 'vue-sweetalert2'
import VueApexCharts from 'vue3-apexcharts'
import BootstrapVue3 from 'bootstrap-vue-3'
import CounterUp from 'vue3-autocounter'

// Custom Components & Directives
import globalComponent from './plugins/global-components'
import globalDirective from './plugins/global-directive'
import globalMixin from './plugins/global-mixin'
require('waypoints/lib/noframework.waypoints.min')
import Highcharts from "highcharts";
import Maps from "highcharts/modules/map";
import HighchartsVue from "highcharts-vue";
import config from './config';

import InitAxios from './service/api';
import Chat from 'vue3-beautiful-chat';


/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckSquare } from '@fortawesome/free-regular-svg-icons'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import { faChartBar } from '@fortawesome/free-regular-svg-icons'
import { faXmarkCircle } from '@fortawesome/free-regular-svg-icons'
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons'
import { faFileExcel,faListAlt,faBell, faEye,faEyeSlash } from '@fortawesome/free-regular-svg-icons'


/* add icons to the library */
library.add(faCircle,faCheckCircle,faCheckSquare,faFile, faChartBar,faXmarkCircle,faPenToSquare,faFileExcel,faListAlt,faBell,faEye,faEyeSlash)

const token = localStorage.getItem('token');
if (token) {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}
const axiosInstance = axios.create({
    baseURL: config.baseURLApi,
    http2: true,
    headers: {
        "Content-Type":'application/json'
      }
  });
  
  const app = createApp(App)
  app.use(store).use(router).use(i18n)
  
  Maps(Highcharts);
  app.use(HighchartsVue);
  app.use(Chat)
  app.config.productionTip = false;
  app.config.globalProperties.$axios = { ...axiosInstance }

//api
InitAxios();


// Library Components
app.use(VueSweetalert2)
app.use(VueApexCharts)
app.use(BootstrapVue3)
app.component('counter-up', CounterUp,
app.component('font-awesome-icon', FontAwesomeIcon))

// Custom Components & Directives
app.use(globalComponent)
app.use(globalDirective)


app.mixin(globalMixin)

app.mount('#app')

export default app
