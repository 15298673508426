import { createStore, createLogger } from 'vuex'
import setting from './setting'
import axios from "axios";
const debug = process.env.NODE_ENV !== 'production'
export default createStore({
  state: {
    user: null,
    lang: 'en',
    signInPopup: false,
    signUpPopup: false,
    shareOffcanvas: false,
    dropdowns: {
      carts: [
        {
          image: require('@/assets/images/shapes/01.png'),
          product_name: 'Biker’s Jacket',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/02.png'),
          product_name: 'Casual Shoes',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/03.png'),
          product_name: 'Knitted Shrug',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/04.png'),
          product_name: 'Blue Handbag',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/01.png'),
          product_name: 'Biker’s Jacket',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/02.png'),
          product_name: 'Casual Shoes',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/03.png'),
          product_name: 'Knitted Shrug',
          price: '$56.00'
        },
        {
          image: require('@/assets/images/shapes/04.png'),
          product_name: 'Blue Handbag',
          price: '$56.00'
        }
      ],
      days: [],
      DEFAULT: []
    },
    isFetching: false,
  },
  getters: {
    lang: (state) => state.lang,
    carts: (state) => state.dropdowns.carts,
    signInPopup: (state) => state.signInPopup,
    signUpPopup: (state) => state.signUpPopup,
    shareOffcanvas: (state) => state.shareOffcanvas,
    isAuthenticated: (state) => !!state.user,
    StateUser: (state) => state.user,
  },
  mutations: {
    langChangeCommit(state, payload) {
      state.lang = payload
    },
    openModalCommit(state, payload) {
      state[payload.name] = payload.value
    },
    openBottomCanvasCommit(state, payload) {
      state[payload.name] = payload.value
    },

    setFetching(state) {
      state.isFetching = !state.isFetching;
    },
    setUser(state, username) {
      state.user = username;
    },

    setPosts(state, posts) {
      state.posts = posts;
    },
    logout(state, user) {
      state.user = user;
    },


  },
  actions: {
    openModalAction({ commit }, payload) {
      commit('openModalCommit', payload)
    },
    openBottomCanvasAction({ commit }, payload) {
      commit('openBottomCanvasCommit', payload)
    },
    langChangeAction(context, payload) {
      context.commit('langChangeCommit', payload)
    },
    async Register({ dispatch }, form) {
      await axios.post('register', form)
      let UserForm = new FormData()
      UserForm.append('username', form.username)
      UserForm.append('password', form.password)
      await dispatch('LogIn', UserForm)
    },

    async LogIn({ commit }, user) {
      await axios.post("/auth/login", user,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {

          localStorage.setItem("token", response.data.token)
          
          localStorage.setItem("user",JSON.stringify(response.data))
          commit("setUser", response.data);

          return response.data

        }).catch(error => {
          console.log(error)
          return error

        })

    },

    async CreatePost({ dispatch }, post) {
      await axios.post("post", post);
      return await dispatch("GetPosts");
    },

    async GetPosts({ commit }) {
      let response = await axios.get("posts");
      commit("setPosts", response.data);
    },

    async LogOut({ commit }) {
      let user = null;
      commit("logout", user);
    },
  },
  modules: {
    setting: setting
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
