import axios from "axios";
import store from "../store";

// Global axios setup
function InitAxios() {
    // const mode = process.env.NODE_ENV;
    //let baseUrl = 'http://simko.angkasapurasolusi.co.id:3030'
    let baseUrl = 'https://be-simko.angkasapurasolusi.co.id'

    // if (mode == "production") baseUrl = process.env.VUE_APP_API_PROFILE_PROD;
    // if (mode == "development") baseUrl = process.env.VUE_APP_API_PROFILE_DEV;
    // if (mode == "test") baseUrl = process.env.VUE_APP_API_PROFILE_TEST;

    axios.defaults.baseURL = baseUrl;

    axios.interceptors.request.use(
        function (config) {
            store.commit("setFetching");

            return config;
        },
        function (error) {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    axios.interceptors.response.use((res) => {
        store.commit("setFetching");

        return res.data;
    });
}

export default InitAxios;
