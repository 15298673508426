const hostApi = process.env.NODE_ENV === "development" ? "https://bedev-simko.angkasapurasolusi.co.id" : "https://be-simko.angkasapurasolusi.co.id";
const portApi = process.env.NODE_ENV === "development" ? 3030 : "3010";
// const baseURLApi = `${hostApi}${portApi ? `` : ``}`;
const baseURLApi = `${hostApi}`;

//import loginData from "../pages/Login/Login"
 
export default {
  hostApi,
  portApi,
  baseURLApi,
}